import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Heading, useStyleConfig } from '@chakra-ui/react';
import { FormattedMessage } from 'gatsby-plugin-intl';

import ArrowUpIcon from './arrowUpIcon';
import CustomLink from './customLink';

/**
 * Display an open drop down menu with the menu title
 * (This is for the list in the non-desktop mode of the header)
 * The menu consists of a list of links
 */
const ToggledDropDownMenu = ({
  items,
  onClickCallback,
  children,
  defaultIsOpen,
  titleStyles,
  listItemStyles,
  isArrowIconShown,
  noMargin,
  disabled,
}) => {
  const headerCategoryStyles = useStyleConfig('HeaderCategory');
  const headerListItemStyles = useStyleConfig('HeaderListItem');
  const [isOpen, setIsOpen] = useState(defaultIsOpen);

  useEffect(() => {
    setIsOpen(defaultIsOpen);
  }, [defaultIsOpen]);

  const handleClick = () => {
    if (!disabled) {
      setIsOpen((prevIsOpen) => !prevIsOpen);
    }
  };

  return (
    <Box
      margin={noMargin ? '0' : '14px 0'}
      _first={{ marginTop: '0' }}
      _last={{ marginBottom: '0' }}
    >
      <Flex
        justifyContent="space-between"
        alignItems="center"
        cursor={disabled ? 'auto' : 'pointer'}
        paddingRight="20px"
        sx={{ WebkitTapHighlightColor: 'transparent' }}
        onClick={handleClick}
      >
        <Heading as="h5" variant="h5" sx={titleStyles || headerCategoryStyles}>
          {children}
        </Heading>
        {isArrowIconShown && (
          <ArrowUpIcon
            fill="#7F7F7F"
            transform={isOpen ? 'none' : 'rotate(180deg)'}
            transition=".3s transform"
          />
        )}
      </Flex>

      {isOpen ? (
        <Flex flexDirection="column" alignItems="flex-start">
          {items.map((item) => (
            <CustomLink
              key={item.title}
              to={item.href}
              onClick={onClickCallback}
              width="100%"
              padding="20px 20px 20px 39px"
              sx={listItemStyles || headerListItemStyles}
              isExternal={item.isExternal}
            >
              {item.titleTranslationKey ? (
                <FormattedMessage id={item.titleTranslationKey} />
              ) : (
                item.title
              )}
            </CustomLink>
          ))}
        </Flex>
      ) : null}
    </Box>
  );
};

ToggledDropDownMenu.propTypes = {
  children: PropTypes.node,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
      titleTranslationKey: PropTypes.string,
    })
  ).isRequired,
  onClickCallback: PropTypes.func,
  defaultIsOpen: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  titleStyles: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  listItemStyles: PropTypes.object,
  isArrowIconShown: PropTypes.bool,
  noMargin: PropTypes.bool,
  disabled: PropTypes.bool,
};

ToggledDropDownMenu.defaultProps = {
  children: null,
  onClickCallback: null,
  defaultIsOpen: false,
  titleStyles: undefined,
  listItemStyles: undefined,
  isArrowIconShown: true,
  noMargin: false,
  disabled: false,
};

export default ToggledDropDownMenu;
