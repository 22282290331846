import React, { useState, useRef } from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { Box, Flex, Image, Text } from '@chakra-ui/react';
import { injectIntl } from 'gatsby-plugin-intl';
import lang from '../../static/images/icon-lang.svg';
import useOnClickOutside from '../utils/useOnClickOutside';
import ArrowUpIcon from './arrowUpIcon';
import navigate from '../utils/navigate';

export const supportedLocales = ['en', 'zh'];

const getName = (locale) => ({ en: 'English', zh: '繁體中文' }[locale]);

const LangSwitch = ({ intl }) => {
  const ref = useRef();
  const [isExpanded, setExpanded] = useState(false);

  const toggle = () => {
    setExpanded(!isExpanded);
  };

  useOnClickOutside(ref, () => setExpanded(false));

  return (
    <Box
      ref={ref}
      width="100%"
      height={{ base: isExpanded ? '160px' : '52px', lg: '52px' }}
      userSelect="none"
      cursor="pointer"
      position="relative"
      sx={{
        WebkitTapHighlightColor: 'transparent',
      }}
      onClick={toggle}
    >
      <Flex
        justifyContent="space-between"
        alignItems="center"
        width={{ base: '100%', md: '260px' }}
        height="52px"
        padding="8px 14px"
        border="1px solid"
        borderColor="porttoGray03"
        borderRadius="12px"
      >
        <Helmet>
          <html lang={intl.locale} />
        </Helmet>

        <Flex alignItems="center">
          <Image src={lang} marginRight="10px" />
          <Text fontSize="16px" lineHeight="20px" color="porttoGray03">
            {getName(intl.locale)}
          </Text>
        </Flex>

        <ArrowUpIcon
          transform={isExpanded ? 'none' : 'rotate(180deg)'}
          transition=".3s transform"
        />
      </Flex>

      <Box
        position="absolute"
        left="0"
        top="45px"
        width="100%"
        padding="12px 0"
        borderRadius="12px"
        bg="porttoGrayBlue"
        opacity={isExpanded ? 1 : 0}
        pointerEvents={isExpanded ? 'auto' : 'none'}
        transform={isExpanded ? 'translateY(15px)' : 'none'}
        transition=".2s opacity, .2s transform"
        boxShadow="0px 0px 10px rgba(0, 0, 0, 0.1)"
      >
        {supportedLocales.map((locale) => (
          <Text
            key={locale}
            fontSize="16px"
            lineHeight="20px"
            color="porttoGray03"
            padding="10px 24px"
            _hover={{
              opacity: { base: 1, md: 0.8 },
              transform: { base: 'none', md: 'scale(0.98)' },
            }}
            transition=".2s opacity, .2s transform"
            onClick={() => navigate(locale)}
          >
            {getName(locale)}
          </Text>
        ))}
      </Box>
    </Box>
  );
};

LangSwitch.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  intl: PropTypes.object.isRequired,
};

export default injectIntl(LangSwitch);
