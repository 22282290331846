import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Link,
  Text,
  useStyleConfig,
} from '@chakra-ui/react';
import iconTwitter from '../../static/images/icon-twitter.svg';
import iconTelegram from '../../static/images/icon-telegram.svg';
import iconDiscord from '../../static/images/icon-discord.svg';
import iconGitHub from '../../static/images/icon-github.svg';
import iconFacebook from '../../static/images/icon-facebook.svg';

import CustomLink from './customLink';
import LangSwitch from './langSwitch';

// TODO
// const ProductPages = [
//   { title: 'Blocto App', href: '/' },
//   { title: 'Blocto SDK', href: '/' },
//   { title: 'BloctoSwap / IDO', href: '/' },
//   { title: 'BloctoBay', href: '/' },
// ];

const SupportPages = [
  {
    title: 'Help Center',
    href: 'https://portto.zendesk.com/',
    titleTranslationKey: 'blocto.footer.helpCenter',
    isExternal: true,
  },
  {
    title: 'Terms of Use',
    href: '/terms',
    titleTranslationKey: 'blocto.footer.termsOfUse',
  },
  {
    title: 'Privacy Policy',
    href: '/privacy',
    titleTranslationKey: 'blocto.footer.privacyPolicy',
  },
];

const SocialMedias = [
  {
    href: 'https://www.facebook.com/blocto/',
    name: 'Facebook',
    icon: iconFacebook,
  },
  {
    href: 'https://twitter.com/BloctoApp',
    name: 'Twitter',
    icon: iconTwitter,
  },
  {
    href: 'https://t.me/blocto',
    name: 'Telegram',
    icon: iconTelegram,
  },
  {
    href: 'https://discord.gg/H34KZqF',
    name: 'Discord',
    icon: iconDiscord,
  },
  {
    href: 'https://github.com/blocto',
    name: 'GitHub',
    icon: iconGitHub,
  },
];

const Footer = () => {
  const layoutContainerStyles = useStyleConfig('LayoutContainer');
  const [allLinks, setAllLinks] = useState([]);
  const { locale } = useIntl();

  useEffect(() => {
    const BloctoPages = [
      { title: 'Blocto', href: '/' },
      {
        title: 'Blocto Token',
        href: `https://token.blocto.app/${locale === 'zh' ? locale : ''}`,
        titleTranslationKey: 'blocto.footer.bloctoToken',
        isExternal: true,
      },
    ];

    const ResourcesPages = [
      {
        title: 'Developer',
        href: 'https://docs.blocto.app/',
        titleTranslationKey: 'blocto.footer.developer',
        isExternal: true,
      },
      // TODO
      // { title: 'Brand', href: '/', titleTranslationKey: 'blocto.footer.brand' },
    ];
    const links = [
      { titleTranslationKey: 'blocto.footer.company', pages: BloctoPages },
      // TODO
      // { titleTranslationKey: 'blocto.footer.product', pages: ProductPages },
      { titleTranslationKey: 'blocto.footer.resources', pages: ResourcesPages },
      { titleTranslationKey: 'blocto.footer.support', pages: SupportPages },
    ];
    setAllLinks(links);
  }, []);

  const handleClick = (url) => () => {
    window.location.href = url;
  };

  return (
    <Box
      as="footer"
      height={{ base: 'auto', lg: '400px' }}
      bg="porttoGrayBlue"
      overflow="hidden"
    >
      <Flex
        display={{ base: 'flex', md: 'block', lg: 'flex' }}
        flexDirection={{ base: 'column', md: 'row' }}
        sx={layoutContainerStyles}
        height={{ base: 'auto', lg: '332px' }}
        paddingTop={{ base: '60px', md: '80px' }}
        paddingBottom={{ base: '40px', md: '118px' }}
        alignItems={{ base: 'stretch', md: 'flex-start' }}
        justifyContent="space-between"
      >
        <Flex
          flex="1"
          flexWrap={{ base: 'wrap', md: 'unset' }}
          alignItems="flex-start"
          justifyContent="space-between"
          marginRight={{ base: '0', lg: '30.2%' }}
        >
          {allLinks.map(({ titleTranslationKey, pages }) => (
            <Box
              key={titleTranslationKey}
              width={{ base: '41.65%', md: 'unset' }}
              marginTop={{ base: '60px', md: '0' }}
              sx={{
                ':nth-of-type(-n+2)': {
                  marginTop: '0',
                },
              }}
            >
              <Heading
                as="h4"
                variant="h4"
                color="#F6F6F9"
                textTransform="uppercase"
              >
                <FormattedMessage id={titleTranslationKey} />
              </Heading>
              {pages.map((item, index) =>
                item.showInCurrentTab ? (
                  <Button
                    key={item.title}
                    display="block"
                    margin="12px 0"
                    color="porttoGray03"
                    fontSize="16px"
                    padding="0"
                    minWidth="0"
                    height="auto"
                    lineHeight="22px"
                    fontWeight="400"
                    marginTop={index === 0 ? '30px' : undefined}
                    marginBottom={index === pages.length - 1 ? '0' : undefined}
                    onClick={handleClick(item.href)}
                  >
                    {item.titleTranslationKey ? (
                      <FormattedMessage id={item.titleTranslationKey} />
                    ) : (
                      item.title
                    )}
                  </Button>
                ) : (
                  <CustomLink
                    key={item.title}
                    to={item.href}
                    display="block"
                    margin="12px 0"
                    color="porttoGray03"
                    fontSize="16px"
                    lineHeight="22px"
                    marginTop={index === 0 ? '30px' : undefined}
                    marginBottom={index === pages.length - 1 ? '0' : undefined}
                    isExternal={item.isExternal}
                  >
                    {item.titleTranslationKey ? (
                      <FormattedMessage id={item.titleTranslationKey} />
                    ) : (
                      item.title
                    )}
                  </CustomLink>
                )
              )}
            </Box>
          ))}
        </Flex>

        <Flex
          flexDirection="column"
          alignItems="center"
          maxWidth={{ base: '100%', md: '260px' }}
          marginTop={{ base: '60px', lg: '0' }}
          marginBottom="0"
        >
          <Flex
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            maxWidth="276px"
            marginBottom="30px"
          >
            {SocialMedias.map(({ href, name, icon }) => (
              <Link key={name} href={href} isExternal>
                <Image alt={name} src={icon} width="36px" height="36px" />
              </Link>
            ))}
          </Flex>
          <LangSwitch />
        </Flex>
      </Flex>

      <Box maxWidth="1440px" mx="auto">
        <Text
          fontSize="14px"
          lineHeight="20px"
          py={{ base: '20px', md: '24px' }}
          mx={{ base: '20px', md: '30px' }}
          color="rgba(255, 255, 255, .6)"
          borderTop="solid 1px rgba(255, 255, 255, .3)"
          textAlign={{ base: 'center', md: 'left' }}
        >
          © 2022 portto (門戶科技股份有限公司, Tax ID: 85089687). All rights
          reserved.
        </Text>
      </Box>
    </Box>
  );
};

export default Footer;
