import { useIntl } from 'gatsby-plugin-intl';
import React from 'react';
import { Button, Link } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import navigate from '../utils/navigate';

/**
 * Custom link component that helps us perform some additional tasks when navigating.
 * We wrap tasks in {onClick} function and pass it to the component,
 * then it will be a programmatic navigation (execute {onClick} function along with the navigation).
 * If {onClick} funciton does not present, CustomLink simply returns a gatsby Link component.
 */
const CustomLink = (props) => {
  const { onClick, to, isExternal, noHoverStyle, children, ...rest } = props;
  const { locale } = useIntl();

  const handleClick = () => {
    if (isExternal) {
      window.open(to, '_blank').focus();
    } else {
      navigate(locale, to);
    }

    if (onClick) {
      onClick();
    }
  };

  return onClick ? (
    <Button
      padding="0"
      minWidth="0"
      height="auto"
      bg="transparent"
      justifyContent="flex-start"
      _hover={
        noHoverStyle
          ? {}
          : {
              opacity: { base: 1, md: 0.8 },
              transform: { base: 'none', md: 'scale(0.98)' },
            }
      }
      onClick={handleClick}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      {children}
    </Button>
  ) : (
    <Link
      href={isExternal ? to : `${locale === 'zh' ? '/zh' : ''}${to}`}
      isExternal={isExternal}
      _hover={noHoverStyle ? {} : undefined}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      {children}
    </Link>
  );
};

CustomLink.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  isExternal: PropTypes.bool,
  noHoverStyle: PropTypes.bool,
};

CustomLink.defaultProps = {
  children: null,
  onClick: null,
  isExternal: false,
  noHoverStyle: false,
};

export default CustomLink;
