import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Heading, Image, useStyleConfig } from '@chakra-ui/react';
import { FormattedMessage } from 'gatsby-plugin-intl';
import arrowDown from '../../static/images/icon-arrow-down.svg';

import CustomLink from './customLink';

/**
 * The title which comes along with a drop down menu in the header (desktop mode)
 * When the user hovers on the menu title, it will show a drop down menu which consists of a list of links
 */
const HoverDropDownMenu = ({ items, children }) => {
  const headerCategoryStyles = useStyleConfig('HeaderCategory');
  const headerListItemStyles = useStyleConfig('HeaderListItem');

  return (
    <Box
      position="relative"
      cursor="pointer"
      _hover={{
        '> div img': {
          transform: 'rotate(180deg)',
        },
        '> div': { pointerEvents: 'auto', opacity: 1 },
      }}
    >
      <Box width="100%" height="11px" position="absolute" top="100%" />
      <Flex alignItems="center">
        <Heading as="h5" variant="h5" sx={headerCategoryStyles}>
          {children}
        </Heading>
        <Image src={arrowDown} marginLeft="4px" transition=".3s transform" />
      </Flex>
      <Box
        pointerEvents="none"
        opacity="0"
        position="absolute"
        top="calc(100% + 11px)"
        right="0"
        width="max-content"
        minWidth="150px"
        bg="white"
        borderRadius="12px"
        boxShadow="0px 0px 20px rgba(0, 0, 0, 0.05)"
        transition="0.3s opacity"
      >
        {items.map((item) => (
          <CustomLink
            key={item.title}
            sx={{
              ...headerListItemStyles,
              display: 'block',
              padding: '20px',
              _hover: {
                fontWeight: '600',
              },
            }}
            to={item.href}
            isExternal={item.isExternal}
          >
            {item.titleTranslationKey ? (
              <FormattedMessage id={item.titleTranslationKey} />
            ) : (
              item.title
            )}
          </CustomLink>
        ))}
      </Box>
    </Box>
  );
};

HoverDropDownMenu.propTypes = {
  children: PropTypes.node,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
      titleTranslationKey: PropTypes.string,
    })
  ).isRequired,
};

HoverDropDownMenu.defaultProps = {
  children: null,
};

export default HoverDropDownMenu;
