import Bowser from 'bowser';
import { supportedLocales } from '../components/langSwitch';
import navigate from './navigate';

const download = () => {
  const browser = Bowser.parse(window.navigator.userAgent);

  if (browser.platform.type === 'desktop') {
    const { pathname } = window.location;
    let locale = pathname.split('/')[1];
    locale = supportedLocales.includes(locale) ? locale : 'en';
    navigate(locale, '/download');
  } else {
    window.open('https://blocto.app.link/download', '_blank');
  }
};

export default download;
