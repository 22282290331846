import React from 'react';
import { Icon } from '@chakra-ui/react';

const ArrowUpIcon = ({ fill, ...rest }) => {
  return (
    <Icon
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.29279 16.7072C4.10532 16.5197 4 16.2654 4 16.0002C4 15.735 4.10532 15.4807 4.29279 15.2932L11.2928 8.29321C11.4803 8.10574 11.7346 8.00043 11.9998 8.00043C12.2649 8.00043 12.5193 8.10574 12.7068 8.29321L19.7068 15.2932C19.8889 15.4818 19.9897 15.7344 19.9875 15.9966C19.9852 16.2588 19.88 16.5096 19.6946 16.695C19.5092 16.8804 19.2584 16.9856 18.9962 16.9879C18.734 16.9902 18.4814 16.8894 18.2928 16.7072L11.9998 10.4142L5.70679 16.7072C5.51926 16.8947 5.26495 17 4.99979 17C4.73462 17 4.48031 16.8947 4.29279 16.7072Z"
        fill={fill ?? '#F9F9F9'}
      />
    </Icon>
  );
};

export default ArrowUpIcon;
