/* eslint-disable max-len */
import { Icon } from '@chakra-ui/react';
import React from 'react';
import PropTypes from 'prop-types';

const Logo = ({ fill, ...rest }) => (
  <Icon
    width="131"
    height="32"
    viewBox="0 0 131 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
  >
    <path
      d="M24.4272 20.3752C24.0731 20.0998 23.6404 20.0015 23.2076 20.0998C22.5192 20.2572 22.0471 20.867 22.0668 21.5554C22.1258 23.247 21.7521 26.4533 18.6048 28.9514C18.0344 29.4038 17.8967 30.2299 18.3098 30.8397C18.5262 31.1741 18.8999 31.4101 19.2933 31.4888C19.372 31.5085 19.4507 31.5085 19.5293 31.5085C19.8637 31.5085 20.1784 31.3905 20.4538 31.1938C24.6042 27.9089 25.0763 23.6798 24.9976 21.4374C24.9583 21.0243 24.7616 20.6309 24.4272 20.3752Z"
      fill={fill}
    />
    <path
      d="M18.3294 26.2369C18.6245 25.9222 18.7425 25.5091 18.7032 25.096C18.6442 24.6633 18.4081 24.2896 18.0344 24.0732C17.4443 23.6994 16.6968 23.7978 16.2247 24.3092C15.0839 25.5288 12.5661 27.5155 8.59272 27.1024C7.86493 27.0237 7.19614 27.5155 7.03878 28.2236C6.9601 28.617 7.03878 29.0301 7.27482 29.3644C7.51087 29.6988 7.8846 29.9152 8.278 29.9545C8.76975 30.0136 9.24184 30.0332 9.69425 30.0332C14.12 30.0136 16.9722 27.6925 18.3294 26.2369Z"
      fill={fill}
    />
    <path
      d="M10.068 24.978C10.4811 24.9583 10.8745 24.7616 11.1302 24.4272C11.4056 24.0732 11.504 23.6404 11.4056 23.2077C11.2482 22.5192 10.6385 22.0472 9.95001 22.0668C8.25838 22.1258 5.05215 21.7521 2.55404 18.6049C2.08196 18.0148 1.27549 17.8968 0.665711 18.2902C0.331319 18.5065 0.0952772 18.8803 0.0165967 19.2737C-0.0424137 19.6867 0.0559369 20.0998 0.311649 20.4342C3.38019 24.3092 7.29455 24.978 9.61562 24.978C9.77298 24.9977 9.93034 24.978 10.068 24.978Z"
      fill={fill}
    />
    <path
      d="M5.28811 18.3295C5.54382 18.5656 5.89788 18.7032 6.25195 18.7032C6.31096 18.7032 6.36997 18.7032 6.42898 18.6836C6.86172 18.6246 7.23545 18.3885 7.45182 18.0148C7.82556 17.4247 7.7272 16.6772 7.21578 16.2051C5.99623 15.0643 4.00955 12.5465 4.42262 8.57313C4.5013 7.84534 4.00955 7.17655 3.30143 7.01919C2.90802 6.94051 2.49495 7.01919 2.16056 7.25523C1.82617 7.49127 1.60979 7.86501 1.57045 8.27808C1.01969 13.5103 3.65549 16.8346 5.28811 18.3295Z"
      fill={fill}
    />
    <path
      d="M7.07808 11.1302C7.33379 11.3269 7.64851 11.4449 7.98291 11.4449C8.10093 11.4449 8.19928 11.4253 8.3173 11.4056C9.00575 11.2482 9.47784 10.6385 9.45817 9.95001C9.39915 8.25838 9.77289 5.05215 12.9201 2.55404C13.4905 2.10163 13.6282 1.27549 13.2152 0.665711C12.9988 0.331319 12.6251 0.0952772 12.2317 0.0165967C11.8186 -0.0424137 11.4055 0.055937 11.0711 0.311649C6.92072 3.59656 6.44864 7.82564 6.52732 10.068C6.54699 10.4811 6.74369 10.8745 7.07808 11.1302Z"
      fill={fill}
    />
    <path
      d="M12.8022 6.42904C12.8612 6.86178 13.0973 7.23551 13.471 7.45188C13.7071 7.60925 13.9628 7.66826 14.2382 7.66826C14.6316 7.66826 15.0053 7.5109 15.2807 7.21584C16.4215 5.99629 18.959 4.00961 22.9127 4.42268C23.6405 4.50136 24.3093 4.00961 24.4666 3.30149C24.5453 2.90808 24.4666 2.49501 24.2306 2.16062C23.9945 1.82623 23.6208 1.60986 23.2077 1.57051C17.9951 1.01975 14.6906 3.65555 13.176 5.28817C12.8809 5.58322 12.7432 6.01596 12.8022 6.42904Z"
      fill={fill}
    />
    <path
      d="M31.1936 11.0712C27.9087 6.92084 23.6797 6.44876 21.4373 6.52744C21.0242 6.54711 20.6308 6.74381 20.3751 7.0782C20.0997 7.43226 20.0013 7.86501 20.0997 8.29775C20.2571 8.9862 20.8668 9.45829 21.5553 9.43862C23.2469 9.37961 26.4531 9.75334 28.9513 12.9006C29.2266 13.2546 29.6397 13.4317 30.0528 13.4317C30.3282 13.4317 30.6035 13.353 30.8396 13.1956C31.174 12.9792 31.41 12.6055 31.4887 12.2121C31.5674 11.8187 31.4494 11.386 31.1936 11.0712Z"
      fill={fill}
    />
    <path
      d="M26.2368 13.1759C25.9221 12.8808 25.509 12.7628 25.096 12.8021C24.6632 12.8611 24.2895 13.0972 24.0731 13.4709C23.6994 14.061 23.7977 14.8085 24.3092 15.2806C25.5287 16.4214 27.5154 18.9392 27.1023 22.9126C27.0236 23.6404 27.5154 24.3092 28.2235 24.4665C28.3219 24.4862 28.4202 24.5059 28.5186 24.5059C28.8136 24.5059 29.1087 24.4075 29.3644 24.2305C29.6988 23.9944 29.9151 23.6207 29.9545 23.2076C30.4856 17.9951 27.8498 14.6905 26.2368 13.1759Z"
      fill={fill}
    />
    <path
      d="M46.6938 6.86176C44.4514 6.86176 42.5828 7.21582 41.2059 7.6879C40.9108 7.78625 40.7141 8.0813 40.7141 8.39603V27.8104C40.7141 28.0858 40.9305 28.3219 41.2255 28.3219H44.3924C44.6678 28.3219 44.9039 28.1055 44.9039 27.8104V22.4208C45.494 22.6962 46.3988 22.8929 47.5396 22.8929C51.8277 22.8929 54.4242 19.726 54.4242 14.8478C54.4045 9.7926 51.4343 6.86176 46.6938 6.86176ZM46.7922 19.6473C46.2217 19.6473 45.6907 19.5293 45.2973 19.3916C45.0612 19.3129 44.9039 19.0769 44.9039 18.8409V10.6778C44.9039 10.3827 45.1202 10.1467 45.4153 10.0877C45.7497 10.0286 46.1627 10.009 46.6545 10.009C49.0739 10.009 50.2345 11.7596 50.2345 14.8478C50.2148 17.8574 49.1919 19.6473 46.7922 19.6473Z"
      fill={fill}
    />
    <path
      d="M64.7707 6.86176C60.4039 6.86176 57.6895 10.1467 57.6895 14.8872C57.6895 19.6277 60.3449 22.9126 64.7707 22.9126C69.2161 22.9126 71.8519 19.6277 71.8519 14.8872C71.8519 10.1467 69.1571 6.86176 64.7707 6.86176ZM64.7707 19.6473C62.725 19.6473 61.8792 17.6213 61.8792 14.8675C61.8792 12.1334 62.7053 10.0286 64.7707 10.0286C66.8164 10.0286 67.6425 12.1137 67.6425 14.8675C67.6229 17.6213 66.7967 19.6473 64.7707 19.6473Z"
      fill={fill}
    />
    <path
      d="M123.919 6.86176C119.552 6.86176 116.837 10.1467 116.837 14.8872C116.837 19.6277 119.493 22.9126 123.919 22.9126C128.364 22.9126 131 19.6277 131 14.8872C131 10.1467 128.305 6.86176 123.919 6.86176ZM123.919 19.6473C121.873 19.6473 121.027 17.6213 121.027 14.8675C121.027 12.1334 121.853 10.0286 123.919 10.0286C125.964 10.0286 126.79 12.1137 126.79 14.8675C126.771 17.6213 125.964 19.6473 123.919 19.6473Z"
      fill={fill}
    />
    <path
      d="M84.7753 7.47157C84.5786 8.29771 84.3622 9.1632 84.1655 10.009C84.1262 10.1467 84.0082 10.2254 83.8705 10.186C83.2214 10.0287 82.769 9.98934 82.1198 9.98934C81.097 9.98934 80.4282 10.1664 80.0742 10.3041V22.4209C80.0742 22.6962 79.8578 22.9323 79.5627 22.9323H76.3762C76.1008 22.9323 75.8647 22.7159 75.8647 22.4209V8.35672C75.8647 8.14035 76.0024 7.96332 76.1795 7.88464C77.4777 7.37322 79.2283 6.94047 81.4511 6.88146C82.3559 6.86179 83.5754 6.97981 84.5786 7.17651C84.7163 7.19619 84.8147 7.33388 84.7753 7.47157Z"
      fill={fill}
    />
    <path
      d="M99.1736 21.8898C99.1933 22.1258 99.0753 22.3422 98.8589 22.4209C98.2491 22.6569 97.423 22.8733 96.4198 22.9126C91.7383 23.0896 91.0302 20.0211 90.8728 16.7362C90.8532 15.0642 90.8138 11.7203 90.8138 10.0287H89.0829C88.9452 10.0287 88.8271 9.91066 88.8271 9.77297V7.1175C88.8271 6.97981 88.9452 6.86179 89.0829 6.86179H90.8138V3.43918C90.8138 3.30149 90.9319 3.18347 91.0695 3.18347H94.7675C94.9052 3.18347 95.0232 3.30149 95.0232 3.43918V6.88146H98.6622C98.7999 6.88146 98.9179 6.99948 98.9179 7.13717V9.77297C98.9179 9.91066 98.7999 10.0287 98.6622 10.0287H95.0232V16.5592C95.0232 18.6835 95.2789 19.5687 96.6362 19.6867C97.3246 19.7261 97.9541 19.6277 98.6032 19.3917C98.7606 19.3326 98.9179 19.431 98.9376 19.608C98.9966 20.3358 99.0753 21.103 99.1736 21.8898Z"
      fill={fill}
    />
    <path
      d="M113.297 21.8898C113.317 22.1258 113.199 22.3422 112.982 22.4209C112.372 22.6569 111.546 22.8733 110.543 22.9126C105.862 23.0896 105.153 20.0211 104.996 16.7362C104.976 15.0642 104.937 11.7203 104.937 10.0287H103.206C103.068 10.0287 102.95 9.91066 102.95 9.77297V7.1175C102.95 6.97981 103.068 6.86179 103.206 6.86179H104.937V3.43918C104.937 3.30149 105.055 3.18347 105.193 3.18347H108.891C109.029 3.18347 109.147 3.30149 109.147 3.43918V6.88146H112.786C112.923 6.88146 113.041 6.99948 113.041 7.13717V9.77297C113.041 9.91066 112.923 10.0287 112.786 10.0287H109.147V16.5592C109.147 18.6835 109.402 19.5687 110.759 19.6867C111.448 19.7261 112.077 19.6277 112.726 19.3917C112.884 19.3326 113.041 19.431 113.061 19.608C113.12 20.3358 113.218 21.103 113.297 21.8898Z"
      fill={fill}
    />
  </Icon>
);

Logo.propTypes = {
  fill: PropTypes.string,
};

Logo.defaultProps = {
  fill: '#26252D',
};

export default Logo;
