import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import {
  Button,
  Flex,
  Heading,
  Image,
  useBreakpointValue,
  useStyleConfig,
} from '@chakra-ui/react';

import close from '../../static/images/icon-close.svg';
import menu from '../../static/images/icon-menu.svg';
import useOnClickOutside from '../utils/useOnClickOutside';
import download from '../utils/download';

import CustomLink from './customLink';
import HoverDropDownMenu from './headerHoverDropDownMenu';
import Logo from './logo';
import ToggledDropDownMenu from './toggledDropDownMenu';

export const NavHeight = '100px';
export const MobileNavHeight = '76px';

const RWDListContainer = ({ children, isMinWidthLg, isExpanded }) => {
  if (isMinWidthLg) {
    return children;
  }

  return (
    <Flex
      order={3}
      flexDirection="column"
      position="absolute"
      zIndex={-1}
      top={
        isExpanded ? MobileNavHeight : `calc((100vh - ${MobileNavHeight}) * -1)`
      }
      left="0"
      width="100%"
      maxHeight={`calc(100vh - ${MobileNavHeight})`}
      bg="rgba(255, 255, 255, 0.9)"
      backdropFilter="blur(4px)"
      boxShadow="0px 4px 5px rgba(0, 0, 0, 0.1)"
      overflow="auto"
      transition="0.3s top"
    >
      {children}
    </Flex>
  );
};

RWDListContainer.propTypes = {
  children: PropTypes.node,
  isMinWidthLg: PropTypes.bool,
  isExpanded: PropTypes.bool,
};

RWDListContainer.defaultProps = {
  children: null,
  isMinWidthLg: true,
  isExpanded: false,
};

const Header = () => {
  const ref = useRef();
  const layoutContainerStyles = useStyleConfig('LayoutContainer');
  const headerCategoryStyles = useStyleConfig('HeaderCategory');
  const [isExpanded, setExpanded] = useState(false);
  const [bloctoPages, setBloctoPages] = useState([]);
  const isMinWidthLg = useBreakpointValue({ base: false, lg: true });
  useOnClickOutside(ref, () => setExpanded(false));
  const { locale } = useIntl();

  useEffect(() => {
    const pages = [
      {
        title: 'Blocto Token',
        href: `https://token.blocto.app/${locale === 'zh' ? locale : ''}`,
        titleTranslationKey: 'blocto.footer.bloctoToken',
        isExternal: true,
      },
    ];
    setBloctoPages(pages);
  }, [locale]);

  if (isMinWidthLg === undefined) {
    return null;
  }

  const toggle = () => {
    setExpanded(!isExpanded);
  };

  const DropDownMenu = isMinWidthLg ? HoverDropDownMenu : ToggledDropDownMenu;
  return (
    <Flex
      as="nav"
      ref={ref}
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      height={{ base: MobileNavHeight, lg: NavHeight }}
      bg={{ base: 'none', lg: 'rgba(255, 255, 255, 0.9)' }}
      backdropFilter={{ base: 'none', lg: 'blur(20px)' }}
      position="fixed"
      top="0"
      zIndex={100}
    >
      <Flex
        sx={isMinWidthLg ? layoutContainerStyles : {}}
        width="100%"
        height="100%"
        alignItems="center"
        justifyContent="space-between"
      >
        <Flex
          sx={isMinWidthLg === false ? layoutContainerStyles : {}}
          width={{ base: '100%', lg: 'unset' }}
          height="100%"
          alignItems="center"
          justifyContent="space-between"
          bg={{ base: 'rgba(255, 255, 255, 0.9)', lg: 'none' }}
          backdropFilter={{ base: 'blur(20px)', lg: 'none' }}
          boxShadow={{
            base: 'inset 0px -1px 0px rgba(0, 0, 0, 0.05)',
            lg: 'none',
          }}
        >
          <CustomLink to="/" title="portto">
            <Logo width={{ base: '133px', md: '150px' }} maxWidth="unset" />
          </CustomLink>
          <Button
            display={isMinWidthLg ? 'none' : 'block'}
            width="24px"
            minWidth="24px"
            height="24px"
            padding="0"
            bg="transparent"
            onClick={toggle}
          >
            <Image src={isExpanded ? close : menu} />
          </Button>
        </Flex>

        <Flex
          flex={{ base: 'unset', lg: '0 1 617px' }}
          marginLeft={{ base: 0, lg: '80px' }}
          justifyContent="space-between"
          alignItems="center"
          maxWidth={{
            base: '170px',
            md: '210px',
            lg: 'unset',
          }}
        >
          <RWDListContainer
            isMinWidthLg={!!isMinWidthLg}
            isExpanded={isExpanded}
          >
            <DropDownMenu items={bloctoPages} onClickCallback={toggle}>
              BLOCTO
            </DropDownMenu>

            <CustomLink
              to="https://docs.blocto.app/"
              isExternal
              noHoverStyle
              onClick={!isMinWidthLg && isExpanded ? toggle : null}
            >
              <Heading as="h5" variant="h5" sx={headerCategoryStyles}>
                <FormattedMessage id="blocto.header.developer" />
              </Heading>
            </CustomLink>

            <CustomLink
              to="https://portto.zendesk.com/"
              isExternal
              noHoverStyle
              onClick={!isMinWidthLg && isExpanded ? toggle : null}
            >
              <Heading as="h5" variant="h5" sx={headerCategoryStyles}>
                <FormattedMessage id="blocto.header.support" />
              </Heading>
            </CustomLink>

            <Button
              display={{ base: 'none', lg: 'block' }}
              height="54px"
              px="24px"
              textTransform="capitalize"
              fontSize="16px"
              fontWeight="600"
              lineHeight="20px"
              color="white"
              onClick={download}
            >
              <FormattedMessage id="blocto.common.download" />
            </Button>
          </RWDListContainer>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Header;
