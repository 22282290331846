import { changeLocale } from 'gatsby-plugin-intl';

const replacePath = (path) => path.replace(/^\/zh/, ``);

const navigate = (locale, to = '') => {
  let path = to;
  if (!to) {
    const { pathname } = window.location;
    path = replacePath(pathname) || '/';
  }

  if (locale === 'en') {
    window.location.href = window.location?.origin + path;
  } else {
    changeLocale(locale, path);
  }
};

export default navigate;
