/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, useStyleConfig } from '@chakra-ui/react';
import AOS from 'aos';

import Header, { NavHeight, MobileNavHeight } from './header';
import Footer from './footer';
import '../../static/styles/main.scss';
import 'aos/dist/aos.css'; // You can also use <link> for styles

const Layout = ({ children }) => {
  const layoutContainerStyles = useStyleConfig('LayoutContainer');

  useEffect(() => {
    AOS.init({ once: true });
  }, []);

  return (
    <>
      <Header />
      <Box
        as="main"
        __css={layoutContainerStyles}
        marginTop={{ base: MobileNavHeight, lg: NavHeight }}
      >
        {children}
      </Box>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
